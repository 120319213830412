// Imports
import { useEffect, useState } from "react";
import { getMeetingById } from "../../services/zoom";
import Loader from '../../utils/Loader';
import AWS from 'aws-sdk';

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { getCurrentDate } from "../../middlewares/dates";


function ZoomById({ meetingId }){
    
    const [meeting, setMeeting] = useState({});
    const [participants, setParticipants] = useState({ items: [], url: ''});

    
    useEffect(() => {
        async function loadData(){
            const response = await getMeetingById(meetingId);
            if( response ){
                ( response.status === 200 ) ? setMeeting(response.data) : console.log('llamar a la tostada') ;
            }
        }
        loadData()
    }, [])
    
    // S3 Connect
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    });

    // Get S3 Object
    const S3Object = url => {
        const s3 = new AWS.S3();

        const presignedGETURL = s3.getSignedUrl('getObject', {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            Key: url,
            Expires: 20
        });
        window.open(presignedGETURL);
    }

    return(
        <div className="zoomByIdPadre">
            {   ( Object.keys( meeting ).length > 0 ) ?   
                <>           
                    <div className="header">
                        <div>
                            <h3>{ meeting.name }</h3>
                            <p>Id Meeting: <span>{ meeting.id }</span></p>
                            <p>Última actualización: <span>{ meeting.updated_at }</span></p>
                        </div>
                        <button onClick={() => S3Object( participants.url )} className={( participants.url ) ? 'active' : 'disabled'}>Descargar<FontAwesomeIcon icon={ faFileExcel }/></button>
                    </div>
                    <div className="content">
                        <div className="occurences">
                            <h3>Seleccione Webinar</h3>
                            {   meeting.occurrences.map(({ id, participants, participants_url, start_time }) => (
                                <div onClick={() => setParticipants({ items: participants, url: participants_url })} key={ id } className="card">                                    
                                    { getCurrentDate( start_time, 'all-details' )}
                                </div>
                            ))}
                        </div>
                        <div className="participants">
                            <div className="header">
                                <p>Nombre</p>
                                <p>Entrada</p>
                                <p>Salida</p>
                                <p>Total</p>
                            </div>
                            {   participants.items.map(({ id, email, name, join_time, leave_time, duration }) => (
                                <div key={ id } className="card">
                                    <p>{ name }</p>
                                    <p>{ getCurrentDate( join_time, 'only-hour' )}</p>
                                    <p>{ getCurrentDate( leave_time, 'only-hour' )}</p>
                                    <p>{ Math.round(duration / 60) } mins</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
                : <Loader/>
            }
        </div>
    )
}
export default ZoomById;