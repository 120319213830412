//Import
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

//Context
import "./styles/App.scss";
import Home from "./components/Home";
import Login from "./components/Login";
import Zoom from "./components/zoom/Zoom";
import Informes from "./components/informes/Informes";
import Tae from "./components/tae/Tae"
import { ModalProvider } from './context/ModalContext';
import { TostadasProvider } from './context/TostadasContext';
import { InfoUserProvider } from './context/InfoUserContext';
import { ReportesProvider } from './context/ReportesContext';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

function App() {
  return (
    <Router>
      <AuthenticatedTemplate>  
        <InfoUserProvider>
          <TostadasProvider> 
            <ModalProvider>  
              <ReportesProvider>
                <Routes>
                  <Route exact={ true } path="/" element={<Home />} />
                  <Route exact={ true } path="/zoom" element={<Zoom />} />
                  <Route exact={ true } path="/informes" element={<Informes />} />
                  <Route exact={ true } path="/tae" element={<Tae />} />
                </Routes>
              </ReportesProvider>
            </ModalProvider>
          </TostadasProvider>
        </InfoUserProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route exact={ true } path="/" element={<Login />} />
          <Route exact={ true } path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </UnauthenticatedTemplate>
    </Router>
  );
}

export default App;
