import { useContext, useEffect, useState } from "react";
import {
  getCourseTae,
  getExamsOfTae,
  getQuestionsAnswers,
} from "../../services/tae";

import { TostadasContext } from "../../context/TostadasContext";
import dos from "../../assets/2.PNG"
import tres from "../../assets/3.PNG"

function Tae() {
  const { addToast } = useContext(TostadasContext);
  const [courses, setCourses] = useState({ exams: { titles_exams: [] } });
  const [optionsSelected, setOptionsSelected] = useState({
    fecha: "",
    course: "",
  })
  const [date, setDate] = useState('')
  const [reportOne, setReportOne] = useState("")
  const [reportTwo, setReportTwo] = useState("")
  const [reportThree, setReportThree] = useState("")
  const [reportFour, setReportFour] = useState("")

  const [emailReceiver, setEmailreceiver] = useState("")

  const data = {
    date: date,
    reports: {
      reportOne,
      reportTwo,
      reportThree,
      reportFour
    },
    emailReceptor: emailReceiver,
  }

  // useEffect(() => {
  //   async function loadCourse() {
  //     const response = await getCourseTae();
  //     console.log(response.data);
  //     if (response) {
  //       response.status === 200
  //         ? setCourses(response.data)
  //         : addToast("Error", "No se han podido cargar el TAE", "danger");
  //     }
  //   }
  //   loadCourse();
  // }, []);

  async function downloadCsv(data) {
    const response = await getQuestionsAnswers(data);
    if (response.status == 200) {
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.download = 'data.csv';
      link.click();

      URL.revokeObjectURL(url);
    } else {
      addToast("Error", "No se ha podido descargar el TAE", "danger");
    }
  }
  console.log(courses);
  return (
    <>
      <div className="exams">
        <div className="dateContainer">
          <label>A partir de que fecha quieres el Tae:</label>
          <input type="date" onChange={(e) => { setDate(e.target.value) }} />
        </div>

        <div className='infoBox'>
              <img src={dos} alt="logo" className='logoImg' />
              <p className="infoBox-title">De qué exámenes quieres descargar el TAE</p>

              <div></div>
              <div className='reportsBox'>
                <div className="squaredOne">
                  <input type="checkbox" onChange={(eReportOne) => setReportOne(eReportOne.target.value)} id="squaredOne" name="check" />
                  <label htmlFor="squaredOne"> </label>
                  <p className="reports">Examen UNO.</p>
                </div>

                <div className="squaredTwo">
                  <input type="checkbox" onChange={(eReportTwo) => setReportTwo(eReportTwo.target.value)} id="squaredTwo" name="check" />
                  <label htmlFor="squaredTwo"> </label>
                  <p className="reports">Examen DOS.</p>
                </div>

                <div className="squaredThree">
                  <input type="checkbox" onChange={(eReportThree) => setReportThree(eReportThree.target.value)} id="squaredThree" name="check" />
                  <label htmlFor="squaredThree"> </label>
                  <p className="reports">Examen TRES.</p>
                </div>

                <div className="squaredFour">
                  <input type="checkbox" onChange={(eReportFour) => setReportFour(eReportFour.target.value)} id="squaredFour" name="check" />
                  <label htmlFor="squaredFour"> </label>
                  <p className="reports">Examen CUATRO.</p>
                </div>
              </div>

            </div>

            <div  className='toEmailBox'>
              <img src={tres} alt="logo" className='logoImg' />
              <p className="infoBox-title">Email de destino al que llega el Excel con la información.</p>
              <p className="infoBox-title-to">Para:</p>
              <input
                className="receiver"
                type="text"
                placeholder="Email de contacto"
                onChange={(eEmailTo) => setEmailreceiver(eEmailTo.target.value)}
              />
            </div>

              <button type="submit" className="button">
                Enviar
              </button>
      </div>
      {/* {courses.exams.length > 1 &&
        courses.exams.map((data) => (
          <div className="exams">
            <p>{data.title}</p>
            <div>
              <input
                type="date"
                value={(optionsSelected.course === data.id) && optionsSelected.fecha}
                onChange={(e) =>
                  setOptionsSelected({ course: data.id, fecha: e.target.value })
                }
              />
              <p>Fecha seleccionada: {(optionsSelected.course === data.id) && optionsSelected.fecha}</p>
            </div>
            <button
              className={
                optionsSelected.course === data.id ? "active" : "disable"
              }
              type="submit"
              onClick={() => downloadCsv(optionsSelected)}
            >
              Download
            </button>
          </div>
        ))} */}
    </>
  );
}
export default Tae;
