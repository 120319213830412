import { useEffect, useState } from "react";
import { sendInfo } from "../../services/sendInfo";
import { getCanvas } from "../../services/universities";
import { getTermsByUniversity } from "../../services/terms";

import Loader from "../../utils/Loader";

import uno from "../../assets/1.PNG";
import dos from "../../assets/2.PNG";
import tres from "../../assets/3.PNG";

function Informes() {

  const [canvas, setCanvas] = useState(null);
  const [canvasSelected, setCanvasSelected] = useState("");
  const [terms, setTerms] = useState([]);

  const [info, setInfo] = useState({
    date: "",
    canvas: "",
    term: "",
    emails: [],
    reports: {
      first: null,
      second: null,
      third: null,
    },
    email_receiver: "",
  });

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function handleUniversity() {
      setLoader(true);
      const response = await getCanvas();
      if (response?.status === 200) {
        setCanvas(response.data);
      }
    }
    handleUniversity();
    setLoader(false);
  }, []);

  const handleCanvasSelection = async (event) => {
    setLoader(true);
    setCanvasSelected(event.target.value);
    setInfo({
      ...info,
      canvas: event.target.value
    });
    if (event.target.value) {
      const response = await getTermsByUniversity(event.target.value);
      if (response?.status == 200) {
        setTerms(response.data);
      }
    }
    setLoader(false);
  };

  const handleTermSelection = async (event) => {
    setInfo({
        ...info,
        term: event.target.value
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    const response = await sendInfo(info);
    if(response) {
      setLoader(false)
      window.location.reload();
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="searchBox">
          <form onSubmit={handleSubmit} className="form">
            <div className="logosBox">
              <img src={uno} alt="logo" className="logoImg" />
              <p>
                Sobre quienes <br />
                <br />
                <span>
                  Emails de usuarios sobre los que se necesita información.
                </span>
              </p>

              <div></div>
              <div className="dateContainer">
                <label>A partir de que fecha:</label>
                <input
                  type="date"
                  onChange={(e) =>
                    setInfo({ ...info, date: e.target.value })
                  }
                />
              </div>

              <div className="selects">
                <p>Canvas:</p>
                <select
                  name=""
                  id=""
                  onChange={handleCanvasSelection}
                  className="emails"
                >
                  <option value="">Universidades</option>
                  {canvas !== null &&
                    canvas.map((i, index) => (
                      <option key={index} value={i.name}>
                        {i.name}
                      </option>
                    ))}
                </select>

                <div
                  className={
                    canvasSelected === "" || canvasSelected === "universidades"
                      ? "toHide"
                      : "selects"
                  }
                >
                  <p className="convText">Convocatoria:</p>{" "}
                </div>
                <div
                  className={
                    canvasSelected === "" || canvasSelected === "universidades"
                      ? "toHide"
                      : "selects"
                  }
                >
                  <select
                    name=""
                    id=""
                    onChange={handleTermSelection}
                    className="emails"
                  >
                    <option value="convocatorias">Convocatorias</option>
                    {terms !== [] &&
                      terms.map((conv) => (
                        <option value={conv.id} key={conv.id}>
                          {conv.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div></div>
              <p>De</p>
              <textarea
                className="emails"
                type="text"
                placeholder="Emails de los alumnos de los que se buscan los informes"
                onChange={(e) =>
                  setInfo({
                    ...info,
                    emails: e.target.value.split(/[,\s\n]+/),
                  })
                }
              />
            </div>

            <div className="logosBox">
              <img src={dos} alt="logo" className="logoImg" />
              <p>
                Qué información <br />
                <br />
                <span>Tipos de diferentes informes sobre los usuarios.</span>
              </p>

              <div></div>
              <div className="reportsBox">
                <div className="squaredOne">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setInfo({
                        ...info,
                        reports: {
                          ...info.reports,
                          first: e.target.checked,
                        },
                      })
                    }
                    id="squaredOne"
                    name="check"
                  />
                  <label htmlFor="squaredOne"> </label>
                  <p className="reports">Datos de acceso a la plataforma.</p>
                </div>

                <div className="squaredTwo">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setInfo({
                        ...info,
                        reports: {
                          ...info.reports,
                          second: e.target.checked,
                        },
                      })
                    }
                    id="squaredTwo"
                    name="check"
                  />
                  <label htmlFor="squaredTwo"> </label>
                  <p className="reports">
                    Datos de acceso y páginas visitadas.
                  </p>
                </div>

                <div className="squaredThree">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setInfo({
                        ...info,
                        reports: {
                          ...info.reports,
                          third: e.target.checked,
                        },
                      })
                    }
                    id="squaredThree"
                    name="check"
                  />
                  <label htmlFor="squaredThree"> </label>
                  <p className="reports">Datos por usuario.</p>
                </div>
              </div>

              <img src={tres} alt="logo" className="logoImg" />
              <p>
                Para quién <br />
                <br />
                <span>
                  Email de destino al que llega el Excel con la información.
                </span>
              </p>
              <p>Para</p>
              <input
                className="receiver"
                type="text"
                placeholder="Email de contacto"
                onChange={(e) =>
                  setInfo({
                    ...info,
                    email_receiver: e.target.value,
                  })
                }
              />
              <button type="submit">Enviar</button>
            </div>
          </form>
        </div>
    </>
  );
}
export default Informes;
