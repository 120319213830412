//Imports
import { createContext, useEffect, useState, useCallback } from 'react';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

export const TostadasContext = createContext();
export const TostadasProvider = ({ children }) => {

    const [toasts, setToasts] = useState([]);

    //Agreagar Mensaje
    const addToast = useCallback((title, body, type) => {           // 3 tipos: danger - warning - success
        setToasts( toasts => [...toasts, { title, body, type }] )
    }, [setToasts]);
    
    
    useEffect(() => {
        if( toasts.length > 0 ){
            const contador = setTimeout(() => {
                setToasts( toasts => toasts.slice(1) );
            }, 3000);
            return () => clearTimeout(contador)
        }
    }, [toasts])

    return(
        <TostadasContext.Provider value={{ addToast }}>
            {children}
            <div className='toastPadre'>
                {
                    ( toasts.length > 0 ) &&
                        toasts.map(({title, body, type}, i) => (
                            <div key={i} className={`card ${type}`}>
                                <div className='header'>
                                    <FontAwesomeIcon icon={faExclamation}/>
                                    <p>{title}</p>
                                </div>
                                <p className='body'>{body}</p>
                            </div>
                        ))
                }
            </div>
        </TostadasContext.Provider>
    )
} 

