//Imports
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../middlewares/information";
import logo from '../assets/athena-global-color.svg'

function Login() {
	const { instance } = useMsal();

	const handleLogin = () => {
		instance.loginRedirect(loginRequest).catch((e) => {
			console.log(e.message);
		});
	};

	return (
		<div className="loginPage">
			<div className='loginContainer'>
				<img src={ logo } alt="Athena Imagen" className='logo' />
				<button className="loginButton" onClick={() => handleLogin()}>LOGIN</button>
			</div>
		</div>
	);
}
export default Login;