export const msalConfig = {
    auth: {
        clientId: '3713f410-2eb4-4253-be08-0335064871b1',
        authority: 'https://login.microsoftonline.com/ce6c57e7-70a3-449f-b0b9-300681e00150',
        redirectUri: '/home'
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
}
export const loginRequest = {
    scopes: ['User.Read']
}
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}