// Devuelve el valor correcto de las fechas
export function getCurrentDate( date, type ){  
    if( type === 'all-details' ){
        date = new Date(date) 
        return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`;
    }
    if( type === 'only-hour' ){
        date = new Date(date) 
        return `${date.getHours()}:${date.getMinutes()}`;
    }
    
    if( type === 'details' ){
        return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()} - ${date.getHours()}:${date.getMinutes()}`;
    }else{
        return date.getDate() + '/' + ( date.getMonth() + 1 ) + '/' + date.getFullYear();
    }
}
// Devuelve si las 2 fechas coinciden
export function checkDatesFilter( date1, date2 ){    
    if( // Comparar el día, mes y año
        date1.getDate() === new Date(date2).getDate() && 
        date1.getMonth() + 1 === new Date(date2).getMonth() + 1 &&
        date1.getFullYear() === new Date(date2).getFullYear()
    ){ 
        console.log('Si coiuncide');
        return true
    }else{ // No coincide
        console.log('No coincide bro');
        return false
    }
}

