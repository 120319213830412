import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

export async function sendInfo(data){
    try{
        const response = await axios({
            url: `${baseUrl}/api/informes/get_report`,
            method: 'POST',
            data
        })
        console.log(data);
        return response;
    }catch( error ){
        console.log(error);
        console.log(data);
        return error.response;
    }
}