import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

export async function getCanvas(){
    try{
        const response = await axios({
            url: `${baseUrl}/api/informes/get_canvas`,
            method: 'GET',
        })
        return response;
    }catch( error ){
        console.log(error);
        return error.response;
    }
}