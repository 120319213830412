//Imports
import { useState, useEffect, useContext } from "react";
import Calendar from 'react-calendar';
import { getMeetings } from "../../services/zoom";
import ZoomById from "./ZoomById";

//Middlewares
import { checkDatesFilter, getCurrentDate } from '../../middlewares/dates';

//Utils
import FormCreate from "../../utils/FormCreate";
import BackButton from "../../utils/BackButton";
import ModalButton from "../../utils/ModalButton";

//Context
import { TostadasContext } from "../../context/TostadasContext";
import { ModalContext } from "../../context/ModalContext";

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Loader from "../../utils/Loader";

//Images
import ESADEimg from '../../assets/universities/ESADE.png';
import UCPEimg from '../../assets/universities/UCPE.png';
import UEESimg from '../../assets/universities/UEES.png';
import ITAMimg from '../../assets/universities/ITAM.png';
import MITimg from '../../assets/universities/MITDPP.png';

const imagenes = {
    'ESADE': ESADEimg,
    'UCPE': UCPEimg,
    'UEES': UEESimg,
    'ITAM': ITAMimg,
    'MIT': MITimg
}

function Zoom(){

    const [meetings, setMeetings] = useState([]);
    const [filters, setFilters] = useState({ active: false, showFilters: false, university: '', date: '', showDate: false, statusMeeting: null, input: '' });

    // Contexto
    const { addToast } = useContext(TostadasContext);
    const { setOpen, setContent } = useContext(ModalContext);

    // Set meetings
    useEffect(() => {
        async function loadData(){
            const response = await getMeetings();
            if( response ){
                ( response.status === 200 ) ? setMeetings(response.data) : addToast('Error', 'No se han podido cargar las meetings', 'danger');
            }
        }
        loadData()
    }, [])
    

    // Filter Calendario
    const [dayElement, setDayElement] = useState('')
    const selectDay = async (value, event) => {
        setFilters({...filters, date: value}); // Setear día en los filters

		dayElement && dayElement.removeAttribute('id');
		const target = event.target.localName === 'button' ? event.target : event.target.parentElement;
		if( target ){
			target.setAttribute('id', 'selectedDay')
			setDayElement(target)
		}
    }
    // Filtrar Status 
    const handleStatusFilter = type => {
        let brobro;
        if( type ){ 
            // Si el usuario le da a true
            brobro = filters.statusMeeting ? null : true;
            setFilters({...filters, statusMeeting: brobro});
        }else{ 
            // Si el usuario le da a false
            brobro = filters.statusMeeting === false ? null : false;
            setFilters({...filters, statusMeeting: brobro}); 
        }
    }

    // Open Modal And Get Info Meeting by Id
    const callToModalAndInfo = async id => {
        setContent({ title: `Meeting Occurences & Participants`, body: <ZoomById meetingId={ id } />});
        setOpen(true);
    }

    return(
        <div className="zoomPadre">
            <BackButton direction={'/'}/>
            <h1>Reportes Zoom</h1>
            <ModalButton name='Añadir Meeting' props={{title: 'Añadir Meeting', body: <FormCreate />}} />
            <div className="header">
                <input type="text" placeholder="Name o Id el Meeting" onChange={e => setFilters({...filters, input: e.target.value})} value={filters.input} />
                <div className="filterButton" onClick={() => setFilters({...filters, showFilters: !filters.showFilters})}>
                    <p>Filtros</p>
                    <FontAwesomeIcon icon={filters.showFilters ? faChevronUp : faChevronDown }/>
                </div>
                <div className={filters.showFilters ? 'filterOptions active' : 'filterOptions'}>
                    <div>
                        <h3>Selecciona Universidad</h3>
                        <div className="universities">
                            <img onClick={() => setFilters({...filters, university: 'esade'})} className={filters.university === 'esade' ? 'active' : ''} src={ESADEimg} />
                            <img onClick={() => setFilters({...filters, university: 'ucpe'})} className={filters.university === 'ucpe' ? 'active' : ''} src={UCPEimg} />
                            <img onClick={() => setFilters({...filters, university: 'uees'})} className={filters.university === 'uees' ? 'active' : ''} src={UEESimg} />
                            <img onClick={() => setFilters({...filters, university: 'itam'})} className={filters.university === 'itam' ? 'active' : ''} src={ITAMimg} />
                            <img onClick={() => setFilters({...filters, university: 'mit'})} className={filters.university === 'mit' ? 'active' : ''} src={MITimg} />
                        </div>
                    </div>
                    <div>
                        <h3>Fecha de la Webinar</h3>
                        { !filters.showDate ?
                            <p className="clickDate" onClick={() => setFilters({...filters, showDate: true})}>Seleccione Fecha</p>
                            :  
                            <Calendar
                                locale={'es'}
                                calendarType={'ISO 8601'} //SPAIN
                                onClickDay={(value, event) => selectDay(value, event)}
                                tileClassName={({ date }) => getCurrentDate(date, 'short') === getCurrentDate(new Date(), 'short') ? 'isnow' : 'no'}
                                minDate={new Date(new Date().setMonth(new Date().getMonth() - 6))}
                                maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))}
                                defaultView={'month'}
                                minDetail={'year'}
                                nextLabel={<FontAwesomeIcon icon={faAngleDown} />}
                                prevLabel={<FontAwesomeIcon icon={faAngleUp} />}
                                next2Label={null}
                                prev2Label={null}
                            />
                        }
                    </div>
                    <div className="statusPadre">
                        <h3>Status</h3>
                        <div className="statuses">
                            <p onClick={ () => handleStatusFilter(true) } className={ filters.statusMeeting === true ? 'active' : '' }> active </p>
                            <p onClick={ () => handleStatusFilter(false) } className={ filters.statusMeeting === false ? 'active' : '' }> not active </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                {   ( meetings.length > 0 ) ?
                    <>
                        <div className="cardHeader">
                            <p>Id</p>
                            <p>Name</p>
                            <p>University</p>
                            <p>Fecha</p>
                        </div>
                        {
                            meetings.map(({ id, name, university, updated_at }, i) => (
                                ( 
                                    (( filters.input.length > 1 ) ? ( name.includes(  filters.input ) || id.includes(  filters.input ) ) : true ) &&
                                    (( filters.university ) ? filters.university === university : true ) &&
                                    (( filters.date ) ? checkDatesFilter(filters.date, updated_at) : true )
                                ) &&
                                <div key={i} className="card" onClick={() => callToModalAndInfo(id)}>
                                    <p>{ id }</p>
                                    <p>{ name }</p>
                                    <img src={ imagenes[university] } />
                                    <p>{ getCurrentDate(new Date( updated_at ), 'details') }</p>
                                </div>
                            ))
                        }
                    </>
                    : <Loader/>
                }
            </div>
        </div>
    )
}
export default Zoom;

