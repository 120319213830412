// Imports
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

function BackButton({ direction }){
    
    return(
        <Link className="backButtonPadre" to={ direction }>
            <FontAwesomeIcon icon={ faArrowLeft }/>
        </Link>
    )
}
export default BackButton;