//Imports
import { Link } from "react-router-dom"
import athena from "../assets/athena-logo.svg"

function Home() {
	return (
		<div className='home'>
			<div className="homeContainer">
				<img src={ athena } alt="Athena Imagen" />
				<div className="links">
					<Link to="/informes">Informes</Link>
					<Link to="/tae">TAE</Link>
					<Link to="/zoom">Zoom</Link>
				</div>
			</div>
		</div>
	);
}
export default Home;
