// Imports
import { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from "../middlewares/information";
import Loader from "../utils/Loader";


export const InfoUserContext = createContext();
export const InfoUserProvider = ({ children }) => {

    // Validar Sesión Usuario
    const isAuthenticated = useIsAuthenticated();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    // Guardar Informacion del Usuario
    const [userInfoData, setUserInfoData] = useState({name: '', email: '', department: '', role: '', logged: false});

    // Recoger la información del usuario
    const { instance, accounts } = useMsal();

    useEffect(() => {

        // Recoger información del usuario
        if( isAuthenticated && !userInfoData.logged ){ 
            setUserInfoData({
                name: accounts[0].idTokenClaims.name,
                email: accounts[0].idTokenClaims.username,
                role: accounts[0].idTokenClaims.roles[0],
                logged: true
            });
        }
    }, [ userInfoData.logged ])

    // Logout
    const logoutSession = () => {
        instance.logoutRedirect(loginRequest).catch(e => {
            console.log(e.message)
        })
    }
    
	return (
		<InfoUserContext.Provider value={{ userInfoData }}>
			{ children }
            {   ( userInfoData.logged ) ?
                <div className="infoUserPadre">
                    <p>{ userInfoData.name }</p>
                    <button onClick={() => logoutSession()}>cerrar sesión</button>
                </div>
                : <Loader/>
            }
		</InfoUserContext.Provider>
	);
};
