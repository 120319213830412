import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

export async function getTermsByUniversity(universitySelected) {
  try {
    const response = await axios.post(`${baseUrl}/api/informes/get_terms`, {
        name: universitySelected,
    },);

    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
}
