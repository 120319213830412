// Imports
import { useState, useContext } from "react";
import { createMeeting } from "../services/zoom";
import { TostadasContext } from "../context/TostadasContext";

//Images
import esadeImage from '../assets/universities/ESADE.png';
import ucpeImage from '../assets/universities/UCPE.png';
import ueesImage from '../assets/universities/UEES.png';
import itamImage from '../assets/universities/ITAM.png';
import Loader from "./Loader";

function FormCreate(){

    const [meetingInfo, setMeetingInfo] = useState({ university: '', meeting_id: '' });
    const { addToast } = useContext(TostadasContext);
    const [loader, setLoader] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();

        if( meetingInfo.university && meetingInfo.meeting_id ){
            setLoader(true);
            const response = await createMeeting({...meetingInfo, meeting_id: meetingInfo.meeting_id.replace(/\s+/g, '')});
            if( response ){
                response.status === 200 ? addToast('Perfecto', 'Meeting agregada correctamente', 'success') : addToast('Error', 'No se han podido cargar las meetings', 'danger');
            }
        }else{
            addToast('Error', 'Introduce Id Meeting y Selecciona Universidad', 'danger');
        }
    }

    return(
        <form className="formCreatePadre" onSubmit={handleSubmit}>
            { loader && <Loader/> }
            <input placeholder="Id Meeting" type="text" value={meetingInfo.meeting_id} onChange={e => setMeetingInfo({...meetingInfo, meeting_id: e.target.value})}/>
            <div className="universities">
                <img onClick={() => setMeetingInfo({...meetingInfo, university: 'ESADE'})} className={meetingInfo.university === 'ESADE' ? 'active' : ''} src={esadeImage} />
                <img onClick={() => setMeetingInfo({...meetingInfo, university: 'UCPE'})} className={meetingInfo.university === 'UCPE' ? 'active' : ''} src={ucpeImage} />
                <img onClick={() => setMeetingInfo({...meetingInfo, university: 'UEES'})} className={meetingInfo.university === 'UEES' ? 'active' : ''} src={ueesImage} />
                <img onClick={() => setMeetingInfo({...meetingInfo, university: 'ITAM'})} className={meetingInfo.university === 'ITAM' ? 'active' : ''} src={itamImage} />
            </div>
            <button type="submit" className={meetingInfo.university && meetingInfo.meeting_id ? 'active' : ''}>Guardar Meeting</button>
        </form>
    )
}
export default FormCreate;
