//Imports
import { createContext, useState } from "react";

export const ReportesContext = createContext();
export const ReportesProvider = ({ children }) => {

    const [steps, setSteps] = useState(1);
    const [university, setUniversity] = useState('');
    const [usersSelected, setUsersSelected] = useState([]);


	return (
		<ReportesContext.Provider value={{ setSteps, steps, setUniversity, setUsersSelected, usersSelected, university }}>
			{ children }
		</ReportesContext.Provider>
	);
};
