//Imports
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BACKEND_URL;


// Get All Meetings
export async function getMeetings(){
    try {
        const response = await axios({
            url: `${baseUrl}/api/zoom/meetings`,
            method: 'GET'
        })        
        return response
    } catch( error ){
        return error
    }
}
// Get Meeting Info By Id
export async function getMeetingById(id){
    try {
        const response = await axios({
            url: `${baseUrl}/api/zoom/occurrences/${id}`,
            method: 'GET'
        })        
        return response
    } catch( error ){
        return error
    }
}

// Save Meeting By Id & Uni
export async function createMeeting(data){
    console.log(data);
    try {
        const response = await axios({
            url: `${baseUrl}/api/zoom/meeting`,
            method: 'POST',
            data
        })        
        return response
    } catch( error ){
        return error
    }
}


